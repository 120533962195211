import React from "react";

const post = () => {
  const clickPost = () => {
    typeof window !== "undefined" &&
      window.parent.postMessage(
        {
          type: "button-click",
          message: "This is the message I'm sending",
        },
        "*"
      );
  };

  return (
    <div>
      <button onClick={() => clickPost()}>Click here</button>
    </div>
  );
};

export default post;
export { Head } from "../components/Layout/Head";
